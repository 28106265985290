import { IReduxReducer, ITableReducerState } from 'common/types/redux/IReducer';
import {
    SET_CURRENT_PAGE,
    SET_ORDER_BY,
    SET_IS_DESC,
    RESET_INITIAL_STATE_TABLE,
    SET_TABLE_SEARCH_VALUE,
} from 'redux/constants/tableConstants'

const initialState = {
    pageNumber: 1,
    orderBy: null,
    isDesc: null,
    tableSearchValue: ''
} as ITableReducerState

const reducer = (state = initialState, { payload, type }: IReduxReducer) => {
    switch (type) {
        case SET_CURRENT_PAGE:
           return {
                ...state,
                pageNumber: payload,
           }
        case SET_ORDER_BY:
           return {
                ...state,
                orderBy: payload,
           }
        case SET_IS_DESC:
           return {
                ...state,
                isDesc: payload,
           }
        case SET_TABLE_SEARCH_VALUE:
            return {
                ...state,
                tableSearchValue: payload
            }
        case RESET_INITIAL_STATE_TABLE:
           return {
               ...initialState,
           }
        default:
            return state;
    }
};

export default reducer;